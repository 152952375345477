import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var items = 1;
      var pagination = false;
      var navigation = false;
      var breakpoints = [];
      var autoplay = false;
      var direction = 'horizontal';
      var spaceBetween = 0;
      var slideLoop = false;
      var slideChange = false;

      if (this.getData('autoplay')) {
        autoplay = {
          delay: this.getData('autoplay'),
          disableOnInteraction: false,
        };
      }

      if (this.getData('items')) {
        items = this.getData('items');
      }

      if (this.getData('loop')) {
        slideLoop = true;
      }

      if(this.getData('spaceBetween')) {
        spaceBetween = this.getData('spaceBetween');
      }

      if (this.getData('pagination')) {
        pagination = {
          el: this.getData('pagination'),
          clickable: true,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }
      
      if (this.getData('direction')) {
        direction = this.getData('direction');
      }

      if (this.getData('progressbar')) {
        var progressbar = $(this.getData('progressbar'));
        slideChange = function (swiper) {
          var index = swiper.realIndex + 1;
          var slides = Math.ceil(this.el.querySelectorAll(".swiper-slide").length);
          var progress = (index / slides) * 100;
          if (direction == 'horizontal') {
            progressbar.css('width', progress.toFixed(2) + '%');
          } else {
            progressbar.css('height', progress.toFixed(2) + '%');
          }
        };
      }

      new Swiper(this.el, {
        autoplay: autoplay,
        slidesPerView: parseInt(items),
        spaceBetween: parseInt(spaceBetween),
        // Optional parameters
        direction: direction,
        loop: slideLoop,
        autoHeight: false,

        // If we need pagination
        pagination: pagination,

        // Navigation arrows
        navigation: navigation,

        breakpoints: breakpoints,

        on: {
          init: slideChange,
          realIndexChange: slideChange,
        },

        preloadImages: false,
        lazy: true,
      });
    }
}
