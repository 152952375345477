import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleOpen',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
                newsevents: 'newsevents',
                resource: 'toggleResource',
            },
            change: {
                change: 'refresh',
                newsevents: 'newsevents',
                checkboxChange: 'checkboxChange',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    refresh() {
        let form = $(this.el);
        let t = this;
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                
                if ($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                if ($('.b-relation_number').length) {
                    $('.b-relation_number').html(response.count);
                }
                if($('.b-relation_texte').length) {
                    $('.b-relation_texte').html(response.langue_texte);
                }
                if ($('.b-relation_pagination').length) {
                    $('.b-relation_pagination').html(response.pagination);
                }
                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    // t.call('scrollTo', '.b-relation_listing', 'Scroll');
                }, 500);
            },
        });
    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }

    newsevents(e) {
        let form = $(this.el);
        // let t = this;
        // jQuery.ajax({
        //     url: this.ajaxurl,
        //     type: 'POST',
        //     data: form.serialize(),
        //     success: function (response) {
        //         if ($('.b-relation_listing').length) {
        //             $('.b-relation_listing').html(response.content);
        //         }

        //         e.currentTarget.closest('.b-relation').getElementsByClassName('-primary')[0].classList.add('-bg-white');
        //         e.currentTarget.closest('.b-relation').getElementsByClassName('-primary')[0].classList.remove('-primary');
        //         e.currentTarget.closest('.button-container').childNodes[1].classList.add('-primary');
        //         e.currentTarget.closest('.button-container').childNodes[1].classList.remove('-bg-white');
        //     },
        // });
        e.currentTarget.closest('.b-relation').getElementsByClassName('-primary')[0].classList.add('-bg-white');
        e.currentTarget.closest('.b-relation').getElementsByClassName('-primary')[0].classList.remove('-primary');
        e.currentTarget.closest('.button-container').childNodes[1].classList.add('-primary');
        e.currentTarget.closest('.button-container').childNodes[1].classList.remove('-bg-white');

        $(form).find('input[name="paged"]').val(1);
        this.refresh();
    }

    toggleOpen(e){
        let target = e.currentTarget.closest('.b-ressource-listing_input');

        if (document.getElementsByClassName('-is-f-open').length > 0){
            if(target.classList.contains('-is-f-open')){
                target.classList.remove('-is-f-open');
            } else{
                document.getElementsByClassName('-is-f-open')[0].classList.remove('-is-f-open');
                target.classList.add('-is-f-open');
            }
        } else{
            target.classList.add('-is-f-open');
        }
    }

    toggleResource(e){
        let target = e.currentTarget.id;
        let text = document.querySelector(`[data-id='${target}']`).innerHTML;
        let form = $(this.el);
        let t = this;

        e.currentTarget.closest('.b-ressource-listing_input').getElementsByClassName('mainLabel')[0].innerHTML = text;

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                if ($('.b-ressource-listing_cards_component').length && response.posts.length > 0) {
                    $('.b-ressource-listing_cards_component').html(response.content);
                    $('.b-ressource-listing_result').html('Results (' + response.posts.length + ')');

                } else{
                    $('.b-ressource-listing_cards_component').html('No results for your research !');
                    document.getElementsByClassName('b-ressource-listing_cards_component')[0].classList.add('c-heading', '-h4', 'u-c-second');
                }
                // setTimeout(() => {
                //     t.call('update', false, 'Scroll'); // update Locomotive Scroll
                //     t.call('scrollTo', '.b-relation_listing', 'Scroll');
                // }, 500);
            },
        });
    }

    pagination(e) {
        const el = e.currentTarget;
        let paged = el.getAttribute('data-paged');
        let form = $(this.el);
        $(form).find('input[name="paged"]').val(paged);

        this.refresh();
    }

    checkboxChange(e) {
        let form = $(this.el);
        const el = e.currentTarget;

        const elements = this.$('checkboxChange');
        elements.forEach(element => {
            if(element !== el) {
                element.closest('.button-container').childNodes[1].classList.add('-bg-white');
                element.closest('.button-container').childNodes[1].classList.remove('-primary');
                $(element).prop('checked', false);
            }
        });

        el.closest('.button-container').childNodes[1].classList.toggle('-bg-white');
        el.closest('.button-container').childNodes[1].classList.toggle('-primary');


        $(form).find('input[name="paged"]').val(1);
        this.refresh();
    }
}
