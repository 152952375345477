import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            header: 'toggleSection',
            onglet: 'toggleOnglet',
            nav: 'toggleNav',
            languages: 'toggleLanguages',
            pageMenu: 'pageMenu',
          }
        }
    }

    init() {
      if (this.getData('open')) {
        this.$('section')[0].classList.add('is-open');
      }

      if (this.getData('type') == 'onglet') {
        const items = this.$('item');
        items.forEach(element => { 
          var index = element.getAttribute('data-index');
          element.style.transform = 'translateX(calc('+index+' * 100%))';
        });

      }
    }

    toggleSection(e) {
      const target = e.currentTarget;
      const section = this.parent('section', target);
      if (section.classList.contains('is-open')) {
        section.classList.remove('is-open');
      } else {
        const sections = this.$('section');
        sections.forEach(element => element.classList.remove('is-open')); // close section opened
        section.classList.add('is-open');
        if(this.getData('scrollto') == 'true') {
          this.call('scrollTo', section, 'Scroll'); // scrollTo section
        }
      }
      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }

    toggleOnglet(e){
      // let text = document.getElementsByClassName('b-onglet_text');
      // let id = Number(e.target.id.split('-')[1]) * -100;
  
      // for (let i = 0; i < text.length; i++){
      //   text[i].style.transform= `translateX(${id}%)`;
      //   if (document.getElementsByClassName('clicked')[0]) document.getElementsByClassName('clicked')[0].classList.remove('clicked');
      //   e.target.classList.add('clicked')
      // }

      const current_index = Number(e.target.getAttribute('data-index'));
      const items = this.$('item');
      items.forEach(element => {
        var index = Number(element.getAttribute('data-index'));
        var multiplier = 0;
        if(index == current_index) {
          element.style.transform = 'translateX(0)';  
        }else {
          multiplier = index - current_index;
          element.style.transform = 'translateX(calc('+ multiplier +' * 100%))';
        }
      });

      document.getElementsByClassName('is-active')[0].classList.remove('is-active');
      e.target.classList.add('is-active');
    }

    toggleNav(e){
      if(e.currentTarget.closest('.-underlink').classList.contains('-is-navActive')){
        e.currentTarget.closest('.-underlink').classList.remove('-is-navActive')
      } else if (document.getElementsByClassName('-is-navActive').length > 0){
        document.getElementsByClassName('-is-navActive')[0].classList.remove('-is-navActive');
        e.currentTarget.closest('.-underlink').classList.add('-is-navActive');
      } else{
        e.currentTarget.closest('.-underlink').classList.add('-is-navActive');
      }
    }

    toggleLanguages(e){
      const target = e.currentTarget.closest('.b-ressource').getElementsByClassName('b-ressource_wrap_bottom')[0];
      target.classList.toggle('is-active');
    }

    pageMenu(e) {
      const el = e.currentTarget;
      const parent = $(el).parent('[data-accordion="section"]');
      const id = parent.attr('data-id');
      $('[data-id='+id+']').toggleClass('is-active');
    }
}
