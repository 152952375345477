import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                submitAjax: 'submitAjax',
                submit: 'submit',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {

    }

    submitAjax(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');

      this.checkForm(form);

      let error = false;
      let msg_captcha = null;
      if(this.getData('captcha')) {
          const captcha = this.getData('captcha');
          const nb_captcha = $('input[name="captcha[]"]:checked').length;
          if(nb_captcha != 1 || !$('#captcha-'+captcha).is(':checked')) {
            error = true;
            msg_captcha = this.getData('errorCaptchaRequired');
            $('#error-captcha').html(msg_captcha);
          }else {
            $('#error-captcha').html('');
          }
      }

      if(form.valid() && !error) {
        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: form.serialize(),
          success: function( response ){
            if(response.redirectto) {
              window.location.href = response.redirectto;
            }
            form.find('.c-form_msg').addClass('u-margin-top-small');
            if (response.msg) {
              form.find('.c-form_msg').html(response.msg);
            }
            if(response.status == 'success') {
              form.find('.c-form_msg').removeClass('error');
              form.find('.c-form_msg').addClass('success');
              if(response.reset) {
                form[0].reset();
              }
  
              // if(response.modal) {
              //   $this.call('close', false, 'Modal'); // update Locomotive Scroll
              // }
            }else {
              form.find('.c-form_msg').removeClass('success');
              form.find('.c-form_msg').addClass('error');
            }
          },
          complete: function() {
            form.find('.c-form_action .c-button').show();
          },
        });
      }else {
        let msg = '<p>Oops! Veuillez remplir les champs.</p>';
        if(form.attr('data-error') && !error) {
          msg = form.attr('data-error');
        }
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html(msg).fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    submit(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        form.submit();
      }else {
        var msg = '<p>Oops! Veuillez remplir les champs.</p>';
        if(form.attr('data-error')) {
          msg = form.attr('data-error');
        }
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html(msg).fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    checkForm(form) {
      var $ = jQuery;
      var $msg_error = 'Ce champ est obligatoire';
      var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';
      var $msg_error_extension = 'Types de fichiers autorisés .jpg, .jpeg, .png .pdf';
      var $msg_error_filesize = 'Fichier trop volumineux, la taille maximale autorisée est de 5Mo';
      var $msg = '';
      var $msg_email = '';

      $.validator.addMethod('filesize', function (value, element, param) {
        return this.optional(element) || (element.files[0].size <= param)
      }, $msg_error_filesize);

      $(form).validate({
        ignore: [],
        lang: 'fr',
        errorElement : 'div',
        errorPlacement: function(error, element) {
          var placement = $(element).data('error');
          if (placement) {
            $(placement).append(error);
          } else {
            error.insertAfter(element);
          }
        },
      });
      const required = this.$('required');
      const rules = [];
      required.forEach(function(element) {
        if(!$(element).parents('.is-hidden').length) {
          if($(element).attr('data-error-required')) {
            $msg = $(element).attr('data-error-required');
          }else {
            $msg = $msg_error;
          }
          if($(element).attr('data-error-email')) {
            $msg_email = $(element).attr('data-error-email');
          }else {
            $msg_email = $msg_error_email;
          }
          $(element).rules('add', { required: true, messages : { required : $msg } });
          if ($(element).attr('type') == 'email') {
            $(element).rules('add', { email: true, messages : { email : $msg_email } });
          }
        }
      });

      const files = this.$('file');
      files.forEach(function(element) {
        if($(element).attr('type') == 'file') {
          $(element).rules('add', { extension: 'jpeg|jpg|png|pdf', filesize : 5242880, messages : { extension : $msg_error_extension } });
        }
      });
    }
}
