import { module } from 'modujs';
import DataTable from 'datatables.net-dt';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        $(this.el).DataTable({
            paging: false,
            searching: false,
            info: false,
        });
    }
}
