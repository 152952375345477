import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            dropdown: 'dropdown',
          },
          change: {
            dropdownSelected: 'dropdownSelected',
          },
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          }
        }
    }

    init() {

    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    dropdown(e) {
      const target = e.currentTarget;
      // $(this.el).find('.c-form_dropdown').removeClass('is-active');
      // $(target).parent('.c-form_dropdown').toggleClass('is-active');
      let elements = document.querySelectorAll('.c-form_dropdown');
        // let el = this.el;
        elements.forEach(element => {
          if(element !== target.parentNode) {
            element.classList.remove('is-active');
          }
        });

        target.parentNode.classList.toggle('is-active');
    }

    dropdownSelected(e) {
      const target = e.currentTarget;
      let text = $(target).attr('data-value');
      let text_container = $(target).parents('.c-form_dropdown').find('.c-form_dropdown_selected');
      let default_text = text_container.attr('data-text');
      $('.c-form-checkbox').removeClass('is-active');
      $(target).parents('.c-form-checkbox').addClass('is-active');

      // $(target).parents('.c-form_dropdown').find('checkbox').each(function() {
      //   if($(this).prop('checked')) {
      //     string += $(this).attr('data-value')+' ';
      //   }
      // });
      var string = $(target).parents('.c-form_dropdown').find('input:radio:checked').map(function() {
        return $(this).attr('data-value');
      }).get();

      if(string == '') {
        string = default_text;
      }else {
        string = string.join(", ");
      }

      text_container.html(string);

      $(target).parents('.c-form_dropdown').removeClass('is-active');

      $('[data-parent]').addClass('is-hidden');

      if($(target).attr('data-id')) {
        $('[data-parent="'+$(target).attr('data-id')+'"]').removeClass('is-hidden');
      }

      if(!$(target).attr('data-disease')) {
        $('[name="filtre-diseases"]').prop('checked', false);
      }

    }
}
