import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: false,
            reloadOnContextChange: true,
            touchMultiplier: 4,
            tablet: {
              smooth: false,
            },
            smartphone: {
              smooth: false,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        let delay = 700;
        let delay_intro = 700;
        if($('html').hasClass('is-transitioned')) {
          delay = 500;
          delay_intro = 500;
        }

        $('.c-anim').each(function(){
          if($(this).hasClass('is-inview')) {
            $(this).addClass('is-loaded');
            setTimeout(() => {
              $(this).addClass('is-animated');
            }, delay);
            // delay += 100;
          }
        });

        $('.svg-button').click(function(){
          window.location.href = $(this).attr('data-href');
        });

        if(document.getElementsByClassName('b-accueil_container').length) {
          if($('.b-accueil_container').hasClass('is-inview')) {
            let container = document.getElementsByClassName('b-accueil')[0];
            let heightWanted = document.getElementsByClassName('b-accueil_wrap')[0].clientHeight;
            container.style.height = `${heightWanted}px`;
            setTimeout(() => {
              let accueil = document.getElementsByClassName('b-accueil_container')[0];
              // let calc = heightWanted + (8.5 * 14);
              // container.style.height = `${calc}px`;
              accueil.style.height = `${heightWanted}px`;
            }, delay_intro);
          }
        }

        const $this = this;
        $('.c-form_dropdown_wrap').hover(function() {
          $this.scroll.stop();
        }, function() {
          $this.scroll.start();
          $this.scroll.update();
        });

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);

            if(func == 'animateNumber') {
              $(obj).prop('Counter',0).animate({
                Counter: $(obj.el).text(),
              }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                  $(obj.el).text(Math.ceil(now).toLocaleString('cs-CZ'));
                },
              });
            }
        });

      this.scroll.on('scroll', (args) => {
        
      })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target, {'offset': -150});
    }

    update() {
      this.scroll.update();
    }

    stop() {
      this.scroll.stop();
    }

}
